import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { getImages } from '../../utils/common';
import Link from '../Link';
import RedemptionList from './RedemptionList';
import { mediaQueries, calcvw } from '../../utils/mediaQueries';
import backgrounImage from '../../assets/images/membership/bg-gift-redemption.jpg';

const Container = styled.div`
  position: relative;
  padding: 70px 0;
  overflow: hidden;

  ${mediaQueries('md')} {
    padding: 6vw 0;
  }
`;

const Header = styled.h1`
  text-align: center;
  margin-bottom: 30px;

  ${mediaQueries('md')} {
    margin-bottom: 8vw;
  }

  span {
    position: relative;
    display: inline-block;
    padding: 5px 10px;
    font-size: 60px;
    font-weight: bold;
    color: #0bbbef;
    text-transform: uppercase;

    ${mediaQueries('md')} {
      font-size: 6vw;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 47%;
      width: 100px;
      height: 1px;
      background-color: #0bbbef;

      ${mediaQueries('md')} {
        width: ${calcvw(100, 767)};
      }
    }

    &::before {
      left: -120px;

      ${mediaQueries('md')} {
        left: ${calcvw(-120, 767)};
      }
    }

    &::after {
      right: -120px;

      ${mediaQueries('md')} {
        right: ${calcvw(-120, 767)};
      }
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  background-color: #0cbbd4;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 200px;
  width: 100%;
  font-size: 18px;
  transition: all 0.2s;
  padding: 10px 0;
  transition: background 0.3s, color 0.3s;
  margin: 0 auto;
  line-height: normal;
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;

  ${mediaQueries('md')} {
    max-width: 400px;
    width: 50%;
    padding: 2vw 0;
    font-size: 3.5vw;
  }

  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
`;

const TncWrapper = styled.div`
  max-width: 980px;
  margin: 0 auto;
  width: 80%;

  ${mediaQueries('md')} {
    width: 95%;
  }
`;

const ClubTitle = styled.h1`
  text-align: center;
  color: #ffffff;
  font-size: 60px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 90px;
  margin-bottom: 20px;

  ${mediaQueries('md')} {
    font-size: 6vw;
    margin: 8vw auto 3.5vw;
  }
`;

const TncTitle = styled.p`
  font-size: 16px;

  ${mediaQueries('md')} {
    font-size: 3vw;
  }
`;

const Tnc = styled.ul`
  /* list-style-position: inside; */
  margin: 0;
  padding-left: 20px;

  ${mediaQueries('md')} {
    padding-left: 3vw;
  }

  li {
    font-size: 12px;

    ${mediaQueries('md')} {
      font-size: 2.2vw;
    }
  }
`;

const Bubble = styled.div`
  position: absolute;
  width: 621px;
  z-index: 0;
`;

const ListContainer = styled.div`
  max-width: 1170px;
  margin: 40px auto 20px;

  ${mediaQueries('md')} {
    width: 95%;
  }
`;

const RightBubble = styled(Bubble)`
  right: 0;
`;

const LeftBubble = styled(Bubble)`
  left: 0;
`;

const useWelcomeData = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "images/membership" } }) {
        nodes {
          name
          childImageSharp {
            fluid(quality:  100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return { images: allFile.nodes };
};

const GiftRedemption = () => {
  const { t } = useTranslation();
  const { isMobileView, locale } = useSelector((state) => state.common);
  const images = getImages(useWelcomeData().images);
  gsap.registerPlugin(ScrollTrigger);
  const containerRef = useRef(null);

  const imagePath_mb = locale === 'zh' ? 'BIO_CRM_Program_Revamp_tent_card_ZH_V1' : 'BIO_CRM_Program_Revamp_tent_card_EN_V1';
  const imagePath = locale === 'zh' ? 'BIO_CRM_Program_Revamp_tent_card_ZH_V1' : 'BIO_CRM_Program_Revamp_tent_card_EN_V1';

  useEffect(() => {
    const element = containerRef.current;
    gsap.fromTo(
      element.querySelector('.bubble-right'),
      {
        bottom: '-20%',
      },
      {
        bottom: '10%',
        scrollTrigger: {
          trigger: element.querySelector('.gift-redemption__content'),
          start: 'top 60%',
          scrub: true,
          markers: false,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = containerRef.current;
    gsap.fromTo(
      element.querySelector('.bubble-left'),
      {
        bottom: '-50%',
      },
      {
        bottom: '0%',
        scrollTrigger: {
          trigger: element.querySelector('.gift-redemption__content'),
          start: 'top 60%',
          scrub: true,
          markers: false,
        },
      }
    );
  }, []);

  return (
    <Container className="gift-redemption" ref={containerRef}>
      {/* <RightBubble className="bubble-right">
        <Img fluid={images['bubble-right']} />
      </RightBubble>
      <LeftBubble className="bubble-left">
        <Img fluid={images['bubble-left']} />
      </LeftBubble> */}
      <div className="position-relative gift-redemption__content">
        <ListContainer>
          <Img fluid={images[isMobileView ? imagePath_mb : imagePath]} />
        </ListContainer>
        
        {/*<ClubTitle>{t('wonder_club')}</ClubTitle>
         <RedemptionList /> */}
        <TncWrapper>
          <TncTitle>{t('wonder_club_terms_and_condition')}</TncTitle>
          <Tnc
            dangerouslySetInnerHTML={{
              __html: t('wonder_club_terms_and_condition_details'),
            }}
          />
        </TncWrapper>
      </div>
    </Container>
  );
};

export default GiftRedemption;
