import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import RedemptionItem from './RedemptionItem';
import Slider from '../Slider';
import { mediaQueries } from '../../utils/mediaQueries';

const useRedemptionList = () => {
  const { allWonderClubJson } = useStaticQuery(graphql`
    {
      allWonderClubJson {
        edges {
          node {
            id
            tabId
            tabName
            colClass
            details {
              title
              content
              icon
            }
          }
        }
      }
    }
  `);

  return allWonderClubJson.edges;
};

const ListContainer = styled.div`
  max-width: 1170px;
  margin: 0 auto 20px;

  ${mediaQueries('md')} {
    width: 95%;
  }
`;

const TabContainer = styled.div`
  background-color: #ffffff;
  color: #0bbbef;
  border-bottom: 1px solid #0bbbef;
`;

const TabItem = styled.div`
  cursor: pointer;
  padding: 20px 0;
  text-transform: uppercase;

  &.active {
    background-color: #0bbbef;
    color: #ffffff;
  }

  ${mediaQueries('md')} {
    padding: 3vw 0;
    font-size: 2.3vw;
  }
`;

const ContentContainer = styled(Slider)``;

const ContentTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;

const RedemptionList = () => {
  const { t } = useTranslation();
  const sliderRef = React.createRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  function onMemberClick(index) {
    sliderRef.current.slickGoTo(index);
  }

  return (
    <ListContainer>
      <TabContainer className="row no-gutters">
        {useRedemptionList().map(({ node }, index) => {
          return (
            <TabItem
              key={index}
              className={`col text-center ${
                currentSlide === index ? 'active' : ''
              }`}
              onClick={() => onMemberClick(index)}
            >
              {t(node.tabName)}
            </TabItem>
          );
        })}
      </TabContainer>
      <ContentContainer
        sliderRef={sliderRef}
        settings={{
          dots: false,
          arrows: false,
          beforeChange: (current, next) => setCurrentSlide(next),
        }}
      >
        {useRedemptionList().map(({ node }, index) => {
          return (
            <div key={index}>
              <ContentTable>
                <tbody>
                  {node.details.map((detail, j) => {
                    return (
                      <RedemptionItem
                        key={j}
                        title={detail.title}
                        content={detail.content}
                        icon={detail.icon}
                        colClass={node.colClass}
                      />
                    );
                  })}
                </tbody>
              </ContentTable>
            </div>
          );
        })}
      </ContentContainer>
    </ListContainer>
  );
};

export default RedemptionList;
