import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MembershipMainVisual from '../../assets/images/membership/membership-main-visual.jpg';
import MembershipMainVisualRight from '../../assets/images/membership/membership-main-visual-right.png';
import { mediaQueries, calcvw } from '../../utils/mediaQueries';

const Container = styled.div`
  position: relative;
  height: 668px;
  background: url(${MembershipMainVisual}) no-repeat center center;
  background-size: cover;
  overflow: hidden;

  ${mediaQueries(1300)} {
    background-size: 140%;
    background-position: right 40% center;
  }

  ${mediaQueries(980)} {
    background-size: 135%;
    background-position-x: 65%;
  }

  ${mediaQueries('md')} {
    background-size: 150%;
    background-position-x: 77%;
    height: ${calcvw(630, 767)};
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 50%;
    right: 0;
    top: 0;
    background: url(${MembershipMainVisualRight}) no-repeat left;
    background-size: cover;

    ${mediaQueries('md')} {
      content: none;
    }
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 63%;
  color: #ffffff;
  width: 440px;
  z-index: 1;

  ${mediaQueries(1500)} {
    left: 67%;
  }

  ${mediaQueries(1300)} {
    /* background: red; */
    left: 70%;
  }

  ${mediaQueries('md')} {
    left: 68%;
    width: ${calcvw(460, 767)};
  }

  h3 {
    text-align: center;
    font-size: 2.6rem;
    font-weight: bold;
    margin-bottom: 1rem;

    ${mediaQueries('md')} {
      font-size: 5.5vw;
    }
  }

  p {
    /* font-weight: bold; */
    font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;

    ${mediaQueries('md')} {
      font-size: 2.3vw;
    }
  }
`;

const Tnc = styled.small`
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: #ffffff;
  font-size: 10px;
  z-index: 1;
  text-align: right;

  ${mediaQueries('md')} {
    /* font-size: 2.1vw; */
  }

  ${mediaQueries('sm')} {
    font-size: 2.1vw;
  }
`;

const MainVisual = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <TextWrapper>
        <h3 dangerouslySetInnerHTML={{ __html: t('membership_main_title') }} />
        <p dangerouslySetInnerHTML={{ __html: t('membership_main_desc') }} />
      </TextWrapper>
      <Tnc dangerouslySetInnerHTML={{ __html: t('membership_main_tnc') }} />
    </Container>
  );
};

export default MainVisual;
