import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mediaQueries } from '../../utils/mediaQueries';

const Image = styled(Img)`
  max-width: 225px;
  margin: 0 auto 20px;

  ${mediaQueries('md')} {
    width: 40%;
    margin-bottom: 3vw;
  }
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;

  ${mediaQueries('md')} {
    font-size: 4vw;
    margin-bottom: 1.5vw;
  }
`;
const Desc = styled.div`
  font-size: 14px;
  text-align: center;

  ${mediaQueries('md')} {
    font-size: 3.5vw;
  }
`;

const WelcomeItem = ({ image, title, desc }) => {
  const { t } = useTranslation();

  return (
    <div className="col-md">
      <Image fluid={image} />
      <Title dangerouslySetInnerHTML={{ __html: t(title) }} />
      <Desc dangerouslySetInnerHTML={{ __html: t(desc) }} />
    </div>
  );
};

export default WelcomeItem;
