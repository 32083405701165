import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import { getImages } from '../../utils/common';
import WelcomeItem from './WelcomeItem';
import { mediaQueries } from '../../utils/mediaQueries';

const useWelcomeData = () => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "images/membership" } }) {
        nodes {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return { images: allFile.nodes };
};

const Container = styled.div`
  margin: 40px auto;
  max-width: 980px;

  ${mediaQueries('md')} {
    margin: 5vw auto;
  }
`;

const WelcomeImage = styled(Img)`
  max-width: 275px;
  margin: 0 auto;

  ${mediaQueries('md')} {
    width: 40%;
  }
`;

const WelcomeText = styled.p`
  font-size: 21px;
  text-align: center;
  margin: 40px auto;
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;

  &:lang(en) {
    font-size: 20px;
    letter-spacing: -1px;
  }

  ${mediaQueries('md')} {
    font-size: 4vw;
    font-weight: bold;
    margin: 5vw auto;

    &:lang(en) {
      font-size: 3.5vw;
    }
  }
`;

const WelcomeItems = styled.div`
  font-family: 'futural', Arial, '微軟正黑體', Helvetica, sans-serif;
  & > div:not(:last-child) {
    ${mediaQueries('md')} {
      margin-bottom: 6vw;
    }
  }
`;

const Welcome = () => {
  const { t } = useTranslation();
  const images = getImages(useWelcomeData().images);
  const items = [
    {
      image: images['wel-intro01'],
      title: 'member_wel_intro_title_1',
      desc: 'member_wel_intro_desc_1',
    },
    {
      image: images['wel-intro02'],
      title: 'member_wel_intro_title_2',
      desc: 'member_wel_intro_desc_2',
    },
    {
      image: images['wel-intro03'],
      title: 'member_wel_intro_title_3',
      desc: 'member_wel_intro_desc_3',
    },
  ];

  return (
    <Container>
      <WelcomeImage fluid={images['welcome']} />
      <WelcomeText
        dangerouslySetInnerHTML={{ __html: t('member_welcome_desc') }}
      />
      <WelcomeItems className="row no-gutters justify-content-center">
        {items.map((item, index) => {
          return (
            <WelcomeItem
              key={index}
              title={item.title}
              desc={item.desc}
              image={item.image}
            />
          );
        })}
      </WelcomeItems>
    </Container>
  );
};

export default Welcome;
