import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import { useTranslation } from 'react-i18next';
import MainVisual from '../../components/Membership/MainVisual';
import { mediaQueries } from '../../utils/mediaQueries';
import Welcome from '../../components/Membership/Welcome';
import GiftRedemption from '../../components/Membership/GiftRedemption';

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  ${mediaQueries(980)} {
    width: 980px;
  }

  ${mediaQueries('md')} {
    width: 100%;
  }
`;

const Membership = () => {
  const { t } = useTranslation();

  return (
    <Layout
      fullwidth
      seoSettings={{ title: t('member_meta_title') }}
      hasBreadcrumb={false}
    >
      <Wrapper>
        <MainVisual />
        <Welcome />
        <GiftRedemption />
      </Wrapper>
    </Layout>
  );
};

export default Membership;
