import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { mediaQueries, calcvw } from '../../utils/mediaQueries';

const TableRow = styled.tr`
  color: #000000;
  &:not(:last-child) {
    border-bottom: 1px solid #0bbbef;
  }
`;

const TableCol = styled.td`
  background-color: ${(props) => props.bgColor};
  /* padding: 20px 0; */
  font-size: 14px;
  line-height: 1.2;
  position: relative;

  ${mediaQueries('md')} {
    font-size: 2.5vw;
  }

  &.height-113 {
    height: 113px;

    ${mediaQueries('md')} {
      height: ${calcvw(160, 767)};
    }
  }

  &.height-170 {
    height: 170px;

    ${mediaQueries('md')} {
      height: ${calcvw(240, 767)};
    }
  }

  &.height-85 {
    height: 85px;

    ${mediaQueries('md')} {
      height: ${calcvw(120, 767)};
    }
  }
`;

const Title = styled.div`
  /* padding: 30px 0; */

  ${mediaQueries('md')} {
    font-size: 2.5vw;
  }
`;

const Content = styled.div`
  position: relative;
`;

const List = styled.ul`
  margin: 0;
  padding-left: 30px;
  &.with_icon{
    width: 80%;
  }
  ${mediaQueries('md')} {
    padding-left: 5vw;
    &.with_icon{
      width: 70%;
    }
  }

  li {
    ${mediaQueries('md')} {
      font-size: 2.5vw;
    }
  }
`;

const Icon = styled.div`
  color: #0bbbef;
  border: 3px solid #0bbbef;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 5%;
  font-size: 12px;

  ${mediaQueries('md')} {
    width: ${calcvw(125, 767)};
    height: ${calcvw(125, 767)};
    right: 3%;
    font-size: 2vw;
  }

  span {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
`;

const RedemptionItem = ({ title, content, icon, colClass }) => {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCol width="20%" className={colClass} bgColor="#9de4f9">
        <Title
          className="text-center"
          dangerouslySetInnerHTML={{ __html: t(title) }}
        />
      </TableCol>
      <TableCol width="80%" className={colClass} bgColor="#ffffff">
        <Content>
          <List className={icon ? "with_icon" : ""}
            dangerouslySetInnerHTML={{
              __html: content === null ? '-' : t(content),
            }}
          />
        </Content>
        {icon && (
          <Icon>
            <span
              dangerouslySetInnerHTML={{
                __html: t(icon),
              }}
            />
          </Icon>
        )}
      </TableCol>
    </TableRow>
  );
};

export default RedemptionItem;
